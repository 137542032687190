<template>
  <history-list-card
    :title="monitoringItem.name || 'History'"
    :items="historyList"
    :id-properties="ids"
    :object-id="monitoringItem.id"
    :bench="bench"
    :show-hide-lines-action="Boolean(showHideLinesAction)"
    :show-animate-track-action="Boolean(showAnimateTrackAction)"
    :loading="loading"
    :no-data-message="'No data available'"
    :no-more-data="noMoreData"
    :show-shape="!!showShape"
    infinite
    @infinite="loadMore"
    @click:back="handleBack"
    @onRangeChanged="handleRangeChanged"
    @onHideLinesChanged="handleHideLinesChanged"
  />
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  watch
} from '@vue/composition-api';
import { useHistoryList } from '@/modules/property-history/compositions/historyList';
import { useMap } from '@/compositions/map';
import { savedLat, savedLon } from '@/provider/utils';

export default {
  name: 'HistoryPropertyList',
  props: {
    fromDate: {
      type: String,
      default: ''
    },
    toDate: {
      type: String,
      default: ''
    },
    monitoringItem: {
      type: Object,
      default: () => ({})
    },
    ids: {
      type: Array,
      default: () => []
    },
    bench: {
      type: [Number, String],
      default: 1
    },
    showHideLinesAction: {
      type: Boolean,
      default: false
    },
    showAnimateTrackAction: {
      type: Boolean,
      default: false
    },
    showShape: {
      type: [Boolean, String],
      default: true
    }
  },
  setup(props, { emit, root }) {
    const {
      track: {
        setTrack,
        clearTrack,
        setMapToTrackCenter,
        setTrackVisible,
        currentTrackLineIndex,
        isAnimatingMode,
        showPointInfo
      }
    } = useMap();

    const {
      historyList,
      pageInfo,
      load,
      points,
      variables,
      loadMore,
      loadDescriptionsOnResult,
      loadDescriptions,
      noMoreData,
      loading,
      preventUpdateFromSubscribe
    } = useHistoryList({
      ids: props.ids,
      fromDate: props.fromDate,
      toDate: props.toDate,
      monitoringItem: props.monitoringItem,
      objectId: root.$route.params.objectId,
      clickItemHandler: (item, index) => {
        showPointInfo({
          position: [
            savedLon(item.node?.value?.lon),
            savedLat(item.node?.value?.lat)
          ],
          beacons: item.node?.value?.details?.filteredBeacons || [],
          anchorBeaconId: item.node?.value?.details?.anchorBeacon?.beaconId
        });
      }
    });

    loadDescriptionsOnResult(() => {
      load();
    });

    watch(
      () => isAnimatingMode.value,
      () => {
        preventUpdateFromSubscribe.value = isAnimatingMode.value;
      },
      {
        immediate: true
      }
    );

    const computedHistoryList = computed(() => {
      if (!isAnimatingMode.value) {
        return historyList.value;
      }
      return historyList.value.map((item, index) => {
        if (
          index <
          historyList.value.length - currentTrackLineIndex.value - 1
        ) {
          item.props.opacity = 0.3;
        } else {
          item.props.opacity = 1;
        }
        return item;
      });
    });

    const handleBack = () => {
      emit('stack:back');
    };

    const handleRangeChanged = (fromDate, toDate) => {
      variables.value.filter.recordedAt.greaterThanOrEqualTo = fromDate;

      if (toDate) {
        variables.value.filter.recordedAt.lessThanOrEqualTo = toDate;
      }
    };

    onMounted(() => {
      if (props.monitoringItem.id) {
        loadDescriptions();
      } else {
        load();
      }
    });

    onBeforeUnmount(() => {
      clearTrack();
    });

    watch(
      () => points.value,
      () => {
        if (points.value.length) {
          setTrack(points.value.reverse());
          setMapToTrackCenter();
        }
      }
    );

    const handleHideLinesChanged = hided => {
      setTrackVisible(!hided);
    };

    return {
      pageInfo,
      loadMore,
      historyList: computedHistoryList,
      handleBack,
      handleRangeChanged,
      handleHideLinesChanged,
      loading,
      noMoreData
    };
  }
};
</script>

<style></style>
